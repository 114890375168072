<template>
  <div
    :class="{ 'd-inline-flex align-middle mr-2': !botaoDropdown && botaoAcoes }"
  >
    <template v-if="tipoBotao == 'botao'">
      <b-button :variant="variant" block @click="abrir"> Perfil </b-button>
    </template>
    <template v-else-if="tipoBotao == 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Perfil"
        @click="abrir"
      >
        <i class="fas fa-asterisk"></i>
      </b-button>
    </template>
    <template v-else>
      <b-dropdown-item @click.prevent="abrir">Perfil</b-dropdown-item>
    </template>

    <b-modal
      ref="mdlPerfil"
      title="Perfil"
      @shown="validacao"
      @hidden="limpar"
      @ok="salvar"
      okVariant="success"
      okTitle="Salvar"
      cancelTitle="Cancelar"
      size="lg"
    >
      <b-row>
        <b-col>
          <h5>DADOS DO USUÁRIO:</h5>
          <div class="border-bottom mb-5 col"></div>

          <b-form class="form" novalidate="novalidate" ref="frmPerfil">
            <b-form-group>
              <label>Email:</label>
              <b-form-input
                v-model="frmPerfil.email"
                name="email"
                ref="email"
                plaintext
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                v-model="frmPerfil.nome"
                name="nome"
                ref="nome"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <label>Celular <span class="text-danger">*</span>:</label>
              <b-form-input
                v-model="frmPerfil.celular"
                name="celular"
                ref="celular"
                data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999'], 'placeholder': ''"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>

        <b-col>
          <h5>SEGURANÇA:</h5>
          <div class="border-bottom mb-5 col"></div>

          <div class="d-flex justify-content-between align-items-center">
            <p class="m-0 align-middle">
              Habilitar Autenticação de Dois Fatores
            </p>
            <InputSwitch
              v-model="frmPerfil.habilitar2FA"
              :disabled="checkSuperAdmin || isAccountAdmin"
            ></InputSwitch>
          </div>

          <p class="text-muted">
            Habilitando a autenticação de dois fatores você receberá um código
            de validação em seu email e/ou celular (via Whatsapp ou SMS) para
            confirmar sua identidade toda vez que realizar acesso ao sistema.
          </p>
          <div
            class="alert alert-custom alert-light-danger mb-5 pt-0 pb-0"
            role="alert"
          >
            <div class="alert-icon" style="font-size: 2rem">
              <i class="flaticon-warning"></i>
            </div>
            <div class="alert-text font-weight-bold">
              OBRIGATÓRIO PARA ADMINISTRADORES!
            </div>
          </div>

          <div class="border-bottom mt-5 mb-5 col-12"></div>

          <modal-alterar-senha
            url="usuario/alterar-senha"
            titulo="Alterar Senha do Usuário"
            :exigirSenhaAtual="true"
            tipoBotao="botao"
            :botaoAcoes="false"
            variant="warning"
          ></modal-alterar-senha>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import InputSwitch from "primevue/inputswitch";
import Inputmask from "inputmask";
import ModalAlterarSenha from "@/view/components/ModalAlterarSenha";

import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  props: {
    usuario: {
      type: Object,
      default: () => {}
    },
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "icone"
    },
    botaoAcoes: {
      type: Boolean,
      default: true
    }
  },

  components: {
    InputSwitch,
    "modal-alterar-senha": ModalAlterarSenha
  },

  data() {
    return {
      frmPerfil: {
        email: "",
        nome: "",
        celular: "",
        habilitar2FA: false
      }
    };
  },

  methods: {
    limpar() {
      this.frmPerfil = {
        email: "",
        nome: "",
        celular: "",
        habilitar2FA: false
      };
    },

    abrir() {
      this.frmPerfil.email = this.usuario.login;
      this.frmPerfil.nome = this.usuario.nome;
      this.frmPerfil.celular = this.usuario.celular;

      if (this.checkSuperAdmin || this.isAccountAdmin) {
        this.frmPerfil.habilitar2FA = true;
      } else {
        this.frmPerfil.habilitar2FA = this.usuario.habilitado_2fa;
      }

      this.$refs["mdlPerfil"].show();
    },

    validacao() {
      const frmPerfil = this.$refs["frmPerfil"];
      Inputmask().mask(frmPerfil.querySelectorAll("input"));

      this.fv = formValidation(frmPerfil, {
        fields: {
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          celular: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("atualizar-perfil", this.frmPerfil)
            .then((resposta) => {
              const usuario = { ...this.usuario };
              usuario.habilitado_2fa = this.frmPerfil.habilitar2FA;
              this.$store.dispatch(UPDATE_PERSONAL_INFO, usuario);

              this.$refs["mdlPerfil"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Perfil atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  },

  computed: {
    ...mapGetters(["validatorErrors", "checkSuperAdmin", "isAccountAdmin"]),

    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    }
  }
};
</script>
