<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo">
      <router-link to="/">
        <img :src="siteLogo()" alt="Logo" />
      </router-link>
    </div>
    <div class="brand-tools" v-if="allowMinimize">
      <button
        class="brand-toggle btn btn-sm px-0 d-flex align-items-center justify-content-center"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
        style="margin: 0 !important; padding: 0 !important"
      >
        <span
          class="svg-icon svg-icon-warning svg-icon-xl"
          style="margin: 0 !important"
        >
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="../../../../public/media/comunicante/logo-minimized.png"
              alt="Site Wellon Logo"
              id="LogoMinimized"
              style="display: none; margin: auto"
            />
            <i
              id="AssideToggleIcon"
              class="fa fa-chevron-left"
              aria-hidden="true"
            ></i>
          </div>
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
#LogoMinimized {
  display: none;
  margin-top: 8px;
}
#AssideToggleIcon {
  padding: 0 !important;
  margin: 0 !important;
  display: block;
  margin-top: 12px !important;
  &:hover {
    color: #ffa800 !important;
  }
  &:active {
    color: #ffa800 !important;
  }
}
.aside-minimize #LogoMinimized {
  display: block !important;
}
.aside-minimize #AssideToggleIcon {
  display: none !important;
}
#kt_aside_toggle {
  background: #fff !important;
  color: #fff !important;
  &:hover {
    background: #fff !important;
    color: #fff !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  }
};
</script>
