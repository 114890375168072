var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.filteredMenus),function(menu,i){return [(!menu.submenu && !menu.external)?_c('router-link',{key:i,attrs:{"to":menu.link,"target":menu.target ? menu.target : '',"params":{ payload: _vm.pegarPayload(menu) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],class:['menu-icon', 'icon-lg', menu.icone],attrs:{"title":menu.titulo}}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(menu.titulo)+" "),(menu.label)?_c('span',{class:[
                'label',
                'label-inline',
                'label-sm',
                'ml-2',
                menu.corLabel,
                menu.pulsarLabel
              ]},[_c('span',{staticClass:"position-relative"},[_vm._v(_vm._s(menu.label))]),_c('span',{staticClass:"pulse-ring"})]):_vm._e()])])])]}}],null,true)}):_vm._e(),(menu.submenu)?_c('li',{key:i,staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren(menu.link) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],class:['menu-icon', 'icon-lg', menu.icone],attrs:{"title":menu.titulo}}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.titulo))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('i',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.titulo))])])]),_vm._l((menu.submenu),function(submenu,k){return _c('router-link',{key:k,attrs:{"to":submenu.link,"params":{ payload: 'pegarPayload(submenu)' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link d-flex align-items-center justify-content-center submenu-link",staticStyle:{"gap":"0.5rem"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],class:submenu.icone,staticStyle:{"width":"30px"},attrs:{"title":submenu.titulo}}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(submenu.titulo)+" "),(submenu.label)?_c('span',{class:[
                      'label',
                      'label-inline',
                      'label-sm',
                      'ml-2',
                      submenu.corLabel,
                      submenu.pulsarLabel
                    ]},[_c('span',{staticClass:"position-relative"},[_vm._v(_vm._s(submenu.label))]),_c('span',{staticClass:"pulse-ring"})]):_vm._e()])])])]}}],null,true)})})],2)])]):_vm._e(),(menu.external)?_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],key:i,staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover","title":menu.titulo}},[_c('a',{staticClass:"menu-link",attrs:{"href":menu.link,"target":menu.target ? menu.target : ''}},[_c('i',{class:['menu-icon', 'icon-lg', menu.icone]}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(menu.titulo)+" "),(menu.label)?_c('span',{class:[
              'label',
              'label-inline',
              'label-sm',
              'ml-2',
              menu.corLabel,
              menu.pulsarLabel
            ]},[_c('span',{staticClass:"position-relative"},[_vm._v(_vm._s(menu.label))]),_c('span',{staticClass:"pulse-ring"})]):_vm._e()])])]):_vm._e()]}),_c('li',{staticClass:"menu-item sair",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":_vm.sair}},[_c('i',{staticClass:"menu-icon icon-lg fa fa-sign-out-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Sair ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }