<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    id="kt_aside"
    ref="kt_aside"
  >
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
    >
      <div
        ref="kt_aside_menu"
        id="kt_aside_menu"
        class="aside-menu"
        data-menu-scroll="1"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        v-bind:class="asideMenuClass"
      >
        <div>
          <button
            class="brand-toggle btn btn-sm px-0 d-flex align-items-center justify-content-center w-100 my-2"
            id="kt_aside_toggle"
            ref="kt_aside_toggle"
          >
            <span
              class="svg-icon svg-icon-warning svg-icon-xl"
              style="margin: 0 !important"
            >
              <div class="d-flex align-items-center justify-content-center">
                <i
                  id="AssideToggleIcon"
                  class="fa fa-chevron-right"
                  aria-hidden="true"
                ></i>
              </div>
            </span>
          </button>
          <div
            class="input-group mb-3"
            id="AsideSearcher"
            style="margin-top: -20px !important"
          >
            <div class="input-group-prepend">
              <span class="input-group-text m-0" id="basic-addon1"
                ><i class="fa fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              v-model="search_menus"
            />
          </div>
        </div>
        <KTMenu :search_menus="search_menus"></KTMenu>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#AsideSearcher {
  margin: auto !important;
  width: 90%;
  .input-group-text {
    background: #f1f1f1 !important;
    border: none !important;
    border-radius: 20px 0 0 20px;
  }
  input {
    border-radius: 0 20px 20px 0;
    margin-left: 0 !important;
    padding-left: 0 !important;
    background: #f1f1f1;
    color: #000;
    border: none !important;
  }
}

#AssideToggleIcon {
  padding: 0 !important;
  margin: 0 !important;
  display: block;
  &:hover {
    color: #ffa800 !important;
  }
}

.aside-minimize {
  #AsideSearcher {
    input::placeholder {
      color: transparent;
    }

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .input-group-prepend {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
    }
    input {
      pointer-events: none;
    }
  }
}

.aside-minimize #AssideToggleIcon {
  display: block !important;
}

#AssideToggleIcon {
  display: none !important;
}

.aside-minimize #AsideSearcher {
  display: none !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/view/layout/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/view/layout/aside/Menu.vue";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      search_menus: ""
    };
  },
  components: {
    KTBrand,
    KTMenu
  },
  mounted() {
    this.$nextTick(() => {
      const asideRef = this.$refs["kt_aside"];
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);

      // Init Aside
      KTLayoutAside.init(asideRef);

      asideRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
        item.addEventListener("click", () => {
          KTLayoutAside.getOffcanvas().hide();
        });
      });

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
    });
  },
  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains("aside-fixed")) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm);
            this.outsideTm = null;
          }

          // if the left aside menu is minimized
          if (document.body.classList.contains("aside-minimize")) {
            document.body.classList.add("aside-minimize-hover");

            // show the left aside menu
            document.body.classList.remove("aside-minimize");
          }
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        if (document.body.classList.contains("aside-fixed")) {
          if (this.insideTm) {
            clearTimeout(this.insideTm);
            this.insideTm = null;
          }

          if (document.querySelector(".aside-menu .scroll")) {
            document.querySelector(".aside-menu .scroll").scrollTop = 0;
          }

          // if the left aside menu is expand
          if (document.body.classList.contains("aside-minimize-hover")) {
            // hide back the left aside menu
            document.body.classList.remove("aside-minimize-hover");
            document.body.classList.add("aside-minimize");
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
