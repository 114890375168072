<template>
  <div
    class="subheader py-2 py-lg-4 pr-6 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div class="d-flex justify-content-end w-100" v-if="exibirDateRangePicker">
      <a
        class="btn btn-sm btn-light font-weight-bold mr-2"
        @click.stop.prevent="abrirSelecaoPeriodos"
      >
        <span class="text-muted font-size-base font-weight-bold">{{
          textoDataInicial
        }}</span>
        <span class="text-primary font-size-base font-weight-bolder">{{
          textoDataFinal
        }}</span>
      </a>
      <date-range-picker
        ref="picker"
        opens="left"
        :always-show-calendars="false"
        :ranges="dateRangePicker.periodos"
        :locale-data="dateRangePicker.localizacao"
        :auto-apply="true"
        v-model="dateRangePicker.dataInicioFim"
        @select="periodoSelecionado"
      ></date-range-picker>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}

.vue-daterange-picker {
  min-width: 0 !important;
  padding-top: 27px;

  .daterangepicker {
    font-family: Poppins, Helvetica, "sans-serif";

    &:after,
    &:before {
      display: none !important;
    }

    &.opensleft {
      right: 6px;
    }

    .ranges {
      ul {
        width: 160px !important;
      }

      li {
        padding: 0.7rem 1.75rem;
        font-weight: 500;
        font-size: 1rem;
        color: #7e8299;
        transition: color 0.3s ease;

        &:hover,
        &.active {
          background-color: #f3f6f9;
          color: #3699ff;
          transition: color 0.3s ease;
        }
      }
    }
  }

  .reportrange-text {
    display: none !important;
  }
}

.vue-daterange-picker .show-ranges.hide-calendars {
  width: 160px !important;
  min-width: 160px !important;
}

@media screen and (min-width: 768px) {
  .daterangepicker.show-ranges.show-weeknumbers,
  .daterangepicker.show-ranges {
    min-width: 706px !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    DateRangePicker
  },
  data() {
    return {
      ffl_nova_interface_painel: false,
      dateRangePicker: {
        dataInicioFim: {
          startDate: null,
          endDate: null
        },
        periodos: {},
        mudouRota: false,
        textoDataInicial: "",
        textoDataFinal: "",
        localizacao: {
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Aplicar",
          cancelLabel: "Cancelar",
          fromLabel: "De",
          toLabel: "Para",
          customRangeLabel: "Personalizado",
          weekLabel: "W",
          daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
          monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
          ],
          firstDay: 1
        }
      }
    };
  },
  beforeMount() {
    moment.locale("pt-br");

    const datas = this.obterDatas();

    this.dateRangePicker.dataInicioFim.startDate = datas.ultimos3Dias;
    this.dateRangePicker.dataInicioFim.endDate = datas.hoje;

    this.textoDataInicial = "";
    this.textoDataFinal =
      datas.ultimos3Dias.format("DD/MM/YYYY") +
      " - " +
      datas.hoje.format("DD/MM/YYYY");

    this.dateRangePicker.periodos = {
      Hoje: [datas.hoje.toDate(), datas.hoje.toDate()],
      Ontem: [datas.ontem.toDate(), datas.ontem.toDate()],
      "Últimos 3 dias": [datas.ultimos3Dias.toDate(), datas.hoje.toDate()],
      "Última semana": [datas.ultimaSemana.toDate(), datas.hoje.toDate()],
      "Mês atual": [datas.inicioMesAtual.toDate(), datas.fimMesAtual.toDate()],
      "Mês anterior": [
        datas.inicioMesAnterior.toDate(),
        datas.fimMesAnterior.toDate()
      ],
      "Últimos 30 Dias": [datas.ultimos30Dias.toDate(), datas.hoje.toDate()],
      "Últimos 3 meses": [datas.ultimos3Meses.toDate(), datas.hoje.toDate()],
      "Últimos 6 meses": [datas.ultimos6Meses.toDate(), datas.hoje.toDate()],
      "Últimos 12 meses": [datas.ultimos12Meses.toDate(), datas.hoje.toDate()]
    };
  },
  mounted() {
    const ID_FEATURE_FLAG_NOVO_PAINEL = 3;
    this.$getFeatureFlag(ID_FEATURE_FLAG_NOVO_PAINEL).then((res) => {
      this.ffl_nova_interface_painel = res.ffl_ativo;
    });
    ApiService.get("/dashboard/periodos").then((response) => {
      this.sltPeriodos = response.data.data.sltPeriodos;
    });
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
    exibirDateRangePicker() {
      return (
        ["dashboard", "mapeamento-perfil"].includes(this.$route.name) &&
        !this.ffl_nova_interface_painel
      );
    }
  },
  watch: {
    $route: function () {
      if (this.$route.name.indexOf("mapeamento-perfil") === 0) {
        const datas = this.obterDatas();
        this.dateRangePicker.dataInicioFim.startDate = datas.ultimaSemana;
        this.dateRangePicker.dataInicioFim.endDate = datas.hoje;

        this.textoDataInicial = "";
        this.textoDataFinal =
          datas.ultimaSemana.format("DD/MM/YYYY") +
          " - " +
          datas.hoje.format("DD/MM/YYYY");
        this.obterDatas();
      }
    }
  },
  methods: {
    abrirSelecaoPeriodos() {
      this.$refs.picker.togglePicker(true);
    },
    periodoSelecionado({ startDate: dataInicial, endDate: dataFinal }) {
      const objPeriodo = this.obterPeriodo(dataInicial, dataFinal);
      const route = this.$route.name;
      switch (route) {
        case "dashboard":
          this.$bus.$emit("atualizar:dashboard", objPeriodo);
          break;
        case "mapeamento-perfil":
          this.$bus.$emit("atualizar:mapeamento-perfil", objPeriodo);
          break;
      }

      this.textoDataInicial = objPeriodo.textoDataInicial;
      this.textoDataFinal = objPeriodo.textoDataFinal;
    }
  }
};
</script>
