<template>
  <div
    :class="{ 'd-inline-flex align-middle mr-2': !botaoDropdown && botaoAcoes }"
  >
    <template v-if="tipoBotao == 'botao'">
      <b-button :variant="variant" block @click="abrir">
        Alterar Senha
      </b-button>
    </template>
    <template v-else-if="tipoBotao == 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Alterar Senha"
        @click="abrir"
      >
        <i class="fas fa-asterisk"></i>
      </b-button>
    </template>
    <template v-else>
      <b-dropdown-item @click.prevent="abrir">Alterar Senha</b-dropdown-item>
    </template>

    <b-modal
      ref="mdlAlterarSenha"
      :title="titulo"
      @shown="validacao"
      @hidden="limpar"
      @ok="salvar"
      okVariant="success"
      okTitle="Salvar"
      cancelTitle="Cancelar"
    >
      <div>
        <b-form class="form" novalidate="novalidate" ref="frmAlterarSenha">
          <b-form-group v-if="exigirSenhaAtual">
            <label>Senha Atual <span class="text-danger">*</span>:</label>
            <b-input-group>
              <b-form-input
                v-model="frmAlterarSenha.senhaAtual"
                name="senhaAtual"
                ref="senhaAtual"
                type="password"
                autofocus
                autocomplete="new-password"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-secondary btn-icon"
                  @click="mudarVisibilidade('senhaAtual', $event)"
                  tabindex="-1"
                  ><i class="far fa-eye"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label>Nova Senha <span class="text-danger">*</span>:</label>
            <b-input-group>
              <b-form-input
                v-model="frmAlterarSenha.novaSenha"
                name="novaSenha"
                ref="novaSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-secondary btn-icon"
                  @click="mudarVisibilidade('novaSenha', $event)"
                  tabindex="-1"
                  ><i class="far fa-eye"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label
              >Confirmar Nova Senha <span class="text-danger">*</span>:</label
            >
            <b-input-group>
              <b-form-input
                v-model="frmAlterarSenha.confirmarNovaSenha"
                name="confirmarNovaSenha"
                ref="confirmarNovaSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-secondary btn-icon"
                  @click="mudarVisibilidade('confirmarNovaSenha', $event)"
                  tabindex="-1"
                  ><i class="far fa-eye"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <div class="row mb-4">
            <div class="col-md-12">
              <gerador-senha @usar-senha="usarSenha"></gerador-senha>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    url: {
      type: String,
      required: true
    },
    titulo: {
      type: String,
      default: "Alterar Senha"
    },
    exigirSenhaAtual: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "icone"
    },
    botaoAcoes: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      frmAlterarSenha: {
        id: null,
        senhaAtual: "",
        novaSenha: "",
        confirmarNovaSenha: ""
      }
    };
  },

  methods: {
    limpar() {
      this.frmAlterarSenha = {
        id: null,
        senhaAtual: "",
        novaSenha: "",
        confirmarNovaSenha: ""
      };
    },

    abrir() {
      if (this.id) {
        this.frmAlterarSenha.id = this.id;
      }

      this.$refs["mdlAlterarSenha"].show();
    },

    validacao() {
      const frmAlterarSenha = this.$refs["frmAlterarSenha"];

      this.fv = formValidation(frmAlterarSenha, {
        fields: {
          senhaAtual: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              regexp: {
                enabled: false,
                regexp: "^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$",
                message:
                  "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
              },
              blank: {}
            }
          },
          novaSenha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              regexp: {
                regexp: "^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$",
                message:
                  "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
              },
              blank: {}
            }
          },
          confirmarNovaSenha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              regexp: {
                regexp: "^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$",
                message:
                  "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      const frmAlterarSenha = {
        ...this.frmAlterarSenha,
        exigirSenhaAtual: this.exigirSenhaAtual
      };

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.put(this.url, frmAlterarSenha)
            .then((resposta) => {
              this.$emit("senha:atualizada", resposta.data.data);
              this.$refs["mdlAlterarSenha"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Senha alterada com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    mudarVisibilidade(ref, e) {
      const campo = this.$refs[ref].$el;
      const icone = e.currentTarget.firstChild;

      if (campo.getAttribute("type") === "password") {
        campo.setAttribute("type", "text");
        icone.classList.add("fa-eye-slash");
        icone.classList.remove("fa-eye");
      } else {
        campo.setAttribute("type", "password");
        icone.classList.add("fa-eye");
        icone.classList.remove("fa-eye-slash");
      }
    },

    usarSenha(senha) {
      this.frmAlterarSenha.novaSenha = senha;
      this.frmAlterarSenha.confirmarNovaSenha = senha;
    }
  },

  computed: {
    ...mapGetters(["validatorErrors"]),

    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    }
  }
};
</script>
