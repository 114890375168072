<template>
  <ul class="menu-nav">
    <template v-for="(menu, i) in filteredMenus">
      <router-link
        v-if="!menu.submenu && !menu.external"
        :to="menu.link"
        v-slot="{ href, navigate, isActive, isExactActive }"
        :key="i"
        :target="menu.target ? menu.target : ''"
        :params="{ payload: pegarPayload(menu) }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i
              :class="['menu-icon', 'icon-lg', menu.icone]"
              v-b-tooltip.hover.right
              :title="menu.titulo"
            ></i>
            <span class="menu-text">
              {{ menu.titulo }}
              <span
                v-if="menu.label"
                :class="[
                  'label',
                  'label-inline',
                  'label-sm',
                  'ml-2',
                  menu.corLabel,
                  menu.pulsarLabel
                ]"
              >
                <span class="position-relative">{{ menu.label }}</span>
                <span class="pulse-ring"></span>
              </span>
            </span>
          </a>
        </li>
      </router-link>

      <li
        v-if="menu.submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren(menu.link) }"
        :key="i"
      >
        <a href="#" class="menu-link menu-toggle">
          <i
            :class="['menu-icon', 'icon-lg', menu.icone]"
            v-b-tooltip.hover.right
            :title="menu.titulo"
          ></i>
          <span class="menu-text">{{ menu.titulo }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">{{ menu.titulo }}</span>
              </span>
            </li>

            <router-link
              v-for="(submenu, k) in menu.submenu"
              :to="submenu.link"
              v-slot="{ href, navigate, isActive, isExactActive }"
              :key="k"
              :params="{ payload: 'pegarPayload(submenu)' }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[isActive && 'menu-item-active']"
              >
                <a
                  :href="href"
                  class="menu-link d-flex align-items-center justify-content-center submenu-link"
                  style="gap: 0.5rem"
                  @click="navigate"
                >
                  <i
                    :class="submenu.icone"
                    v-b-tooltip.hover.right
                    :title="submenu.titulo"
                    style="width: 30px"
                  ></i>
                  <span class="menu-text">
                    {{ submenu.titulo }}
                    <span
                      v-if="submenu.label"
                      :class="[
                        'label',
                        'label-inline',
                        'label-sm',
                        'ml-2',
                        submenu.corLabel,
                        submenu.pulsarLabel
                      ]"
                    >
                      <span class="position-relative">{{ submenu.label }}</span>
                      <span class="pulse-ring"></span>
                    </span>
                  </span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="menu.external"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :key="i"
        v-b-tooltip.hover.right
        :title="menu.titulo"
      >
        <a
          :href="menu.link"
          class="menu-link"
          :target="menu.target ? menu.target : ''"
        >
          <i :class="['menu-icon', 'icon-lg', menu.icone]"></i>
          <span class="menu-text">
            {{ menu.titulo }}
            <span
              v-if="menu.label"
              :class="[
                'label',
                'label-inline',
                'label-sm',
                'ml-2',
                menu.corLabel,
                menu.pulsarLabel
              ]"
            >
              <span class="position-relative">{{ menu.label }}</span>
              <span class="pulse-ring"></span>
            </span>
          </span>
        </a>
      </li>
    </template>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item sair">
      <a class="menu-link" @click="sair">
        <i class="menu-icon icon-lg fa fa-sign-out-alt"></i>
        <span class="menu-text"> Sair </span>
      </a>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: #356a7c !important;
  width: unset !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link {
  margin: 2px 8px !important;
  display: flex;
  justify-content: center;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link {
  margin: 2px 8px !important;
}

.menu-item {
  margin: auto;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: #356a7c !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
  color: #356a7c !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
  color: #356a7c !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-arrow {
  color: #356a7c !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: #ef720f !important;
}

.menu-link .menu-text {
  font-size: 13px !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: #ef720f !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
  color: #ef720f !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: #ef720f !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-text {
  color: #ef720f !important;
}
.menu-item {
  border-radius: 8px !important;
  overflow: hidden !important;
  /* width: 95% !important; */
  /* margin: auto !important; */
  .menu-link {
    border-radius: 8px !important;
  }
}
.aside-minimize {
  .menu-item {
    border-radius: 0px !important;
    .submenu-link {
      i {
        width: fit-content !important;
      }
    }
  }
  .menu-item.menu-item-submenu:not(.menu-item-open) {
    /* background: red !important;
    border: 1px solid #ccc !important; */
    position: relative !important;
  }
  .menu-submenu {
    .menu-item {
      display: block !important;
      &:last-child {
        border-bottom: 2px solid #ccc !important;
      }
      &.menu-item-parent {
        display: none !important;
      }
      .menu-link {
        padding: 0 !important;
      }
      .menu-text {
        display: none !important;
      }
    }
  }
}
.aside-minimize:not(.aside-minimize-hover)
  .aside-menu
  .menu-nav
  .menu-item:not([data-menu-submenu-mode="accordion"]).menu-item-open
  > .menu-submenu,
.aside-menu.aside-menu-dropdown
  .menu-nav
  .menu-item:not([data-menu-submenu-mode="accordion"]).menu-item-open
  > .menu-submenu {
  display: block !important;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  props: {
    search_menus: {
      type: String,
      default: ""
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    pegarPayload(menu) {
      return menu.payload ? menu.payload : null;
    },

    sair() {
      this.$bus.$emit("logout:usuario");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "layoutConfig"]),
    filteredMenus() {
      let did_open = false;
      return this.currentUserPersonalInfo.novosMenus.filter((menu) => {
        if (menu.submenu) {
          const has_on_submenu = menu.submenu.find((submenu) => {
            return submenu.titulo
              .toLowerCase()
              .includes(this.search_menus.toLowerCase());
          });
          const menu_element = document.querySelector(
            `.menu-item .menu-link .menu-icon[title='${menu.titulo}']`
          );
          if (!did_open && menu_element?.parentNode) {
            const blue_balls_monkey = menu_element?.parentNode?.parentNode;
            if (blue_balls_monkey?.classList.contains("menu-item-open")) {
              did_open = true;
            } else {
              did_open = true;
              menu_element?.parentNode.click();
            }
          }
          if (has_on_submenu) return true;
        }

        return menu.titulo
          .toLowerCase()
          .includes(this.search_menus.toLowerCase());
      });
    }
  }
};
</script>
